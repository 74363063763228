/*
 * @Author: Prophet prophet@qushe.me
 * @Date: 2024-06-01 11:33:38
 * @LastEditors: Prophet prophet@qushe.me
 * @LastEditTime: 2024-06-02 11:06:19
 * @FilePath: \business-website\src\components\Header.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import React from "react";
import "./Header.css";

const headerLinkList = [
  { href: "#services", linkName: "主页" },
  { href: "#services", linkName: "服务" },
  { href: "#about", linkName: "关于" },
  { href: "#contact", linkName: "联系" },
];

const HeaderLink = ({ href, linkName }) => {
  return (
    <li>
      <a href={href}><span>{linkName}</span></a>
    </li>
  );
};
const Header = () => {
  return (
    <header>
      <nav>
        <ul>
          {headerLinkList.map((item) => (
            <HeaderLink href={item.href} linkName={item.linkName} />
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
