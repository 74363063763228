/*
 * @Author: Prophet prophet@qushe.me
 * @Date: 2024-06-01 11:41:36
 * @LastEditors: Prophet prophet@qushe.me
 * @LastEditTime: 2024-06-01 12:06:20
 * @FilePath: \business-website\src\components\Contact.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from "react";
import "./Contact.css";

const Contact = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    alert("Thank you for your message!");
  };

  return (
    <section id="contact" className="contact">
      <h2>联系 我们</h2>
      <form className="contact-form" onSubmit={handleSubmit}>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Your Name"
          required
        />
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Your Email"
          required
        />
        <textarea
          id="message"
          name="message"
          placeholder="Your Message"
          rows="4"
          required
        ></textarea>
        <button type="submit">Send</button>
      </form>
    </section>
  );
};

export default Contact;
