/*
 * @Author: Prophet prophet@qushe.me
 * @Date: 2024-06-01 11:41:36
 * @LastEditors: Prophet prophet@qushe.me
 * @LastEditTime: 2024-06-01 12:05:42
 * @FilePath: \business-website\src\components\About.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from "react";
import "./About.css";

const About = () => {
  return (
    <section id="about" className="about">
      <h2>关于 我们</h2>
      <p>
        这是我们的关于页面。
      </p>
    </section>
  );
};

export default About;
