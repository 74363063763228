/*
 * @Author: Prophet prophet@qushe.me
 * @Date: 2024-06-01 11:41:36
 * @LastEditors: Prophet prophet@qushe.me
 * @LastEditTime: 2024-06-02 11:08:15
 * @FilePath: \business-website\src\components\Services.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import './Services.css';
import {random,randomColor } from '../comm/index.js';
import { top100Url } from '../comm/constant.js';

const newton100Url = top100Url.map(item => {
  return {
    ...item,
    img:`${item.img}?${(random(9999))}`
  }
})
const Services = () => {
  return (
    <section id="services" className="services">
      
      {newton100Url.map((item,index) => serviceItem({index, bgColor: randomColor(), item: item}))}
      
    </section>
  );
};

export default Services;
function serviceItem({index,bgColor,item}) {

    const openurl = (url) => {
        console.log(url, 'url');
        window.open(url,'_blank');
    }

    return <div className="service-item" style={{backgroundColor:bgColor,backgroundImage: `url(${item.img})`}} onClick={() => openurl(item.url)}>
        {/* <h3> {item.title}</h3>
        <p> {}</p> */}
    </div>;
}

