/*
 * @Author: Prophet prophet@qushe.me
 * @Date: 2024-06-01 12:34:03
 * @LastEditors: Prophet prophet@qushe.me
 * @LastEditTime: 2024-06-02 11:00:55
 * @FilePath: \business-website\src\comm\constans.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
//全球流量前100网站
export const top100Url = [
  {
    title: "Baidu",
    url: "https://www.baidu.com",
    img: "https://random.imagecdn.app/1260/720",
  },
  {
    title: "Jd",
    url: "https://www.jd.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "Taobao",
    url: "https://www.taobao.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "Sina",
    url: "https://www.sina.com.cn",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "Qq",
    url: "https://www.qq.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "163",
    url: "https://www.163.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "Sohu",
    url: "https://www.sohu.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "Douban",
    url: "https://www.douban.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "Zhihu",
    url: "https://www.zhihu.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "Bilibili",
    url: "https://www.bilibili.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "Kugou",
    url: "https://www.kugou.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "Youku",
    url: "https://www.youku.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "360",
    url: "https://www.360.cn",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "Iqiyi",
    url: "https://www.iqiyi.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "Yinyuetai",
    url: "https://www.yinyuetai.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "Letv",
    url: "https://www.letv.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "Mgtv",
    url: "https://www.mgtv.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "2345",
    url: "https://www.2345.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "Youku",
    url: "https://www.youku.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "Letv",
    url: "https://www.letv.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "1905",
    url: "https://www.1905.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "17173",
    url: "https://www.17173.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "2345",
    url: "https://www.2345.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "1905",
    url: "https://www.1905.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "17173",
    url: "https://www.17173.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "2345",
    url: "https://www.2345.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "1905",
    url: "https://www.1905.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "17173",
    url: "https://www.17173.com",
    img: "https://random.imagecdn.app/1280/720",
  },
  {
    title: "2345",
    url: "https://www.2345.com",
    img: "https://random.imagecdn.app/1280/720",
  },
];
