/*
 * @Author: Prophet prophet@qushe.me
 * @Date: 2024-06-01 11:41:36
 * @LastEditors: Prophet prophet@qushe.me
 * @LastEditTime: 2024-06-01 12:06:53
 * @FilePath: \business-website\src\components\Footer.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer>
      <p>&copy; 2024 - 2099 直立人工业. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
