/*
 * @Author: Prophet prophet@qushe.me
 * @Date: 2024-06-01 11:41:36
 * @LastEditors: Prophet prophet@qushe.me
 * @LastEditTime: 2024-06-02 11:06:10
 * @FilePath: \business-website\src\App.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import './App.css';
import Header from './components/Header';
// import Banner from './components/Banner';
import Services from './components/Services';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      {/* <Banner /> */}
      <Services />
      <About />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
